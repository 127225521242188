$white-color: #fff;
$border-radius-base: 3px !default;
$bmd-shadow-umbra-opacity: 0.2 !default;
$bmd-shadow-penumbra-opacity: 0.14 !default;
$bmd-shadow-ambient-opacity: 0.12 !default;

@mixin transform-translate-y($value){
    -webkit-transform:  translate3d(0,$value, 0);
       -moz-transform: translate3d(0, $value, 0);
       -o-transform: translate3d(0, $value, 0);
       -ms-transform: translate3d(0, $value, 0);
       transform: translate3d(0, $value, 0);
}

@mixin shadow-8dp(){
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, $bmd-shadow-penumbra-opacity),
    0 3px 14px 2px rgba(0, 0, 0, $bmd-shadow-ambient-opacity),
    0 5px 5px -3px rgba(0, 0, 0, $bmd-shadow-umbra-opacity);
  }
 
 .tooltip-inner, .mat-tooltip {
    color: $white-color !important;
    line-height: 1.5em;
    background: $white-color;
    border: none;
    border-radius: $border-radius-base !important;
    @include shadow-8dp();
}


.tooltip.in{
    opacity: 1;
    @include transform-translate-y(0px);
}
.tooltip{
    opacity: 0;
    transition: opacity, transform .2s ease;
    @include transform-translate-y(5px);

    &.left{
        .tooltip-arrow{
            border-left-color: $white-color;
        }
    }
    &.right{
        .tooltip-arrow{
            border-right-color: $white-color;
        }
    }
    &.top{
        .tooltip-arrow{
            border-top-color: $white-color;
        }
    }
    &.bottom{
        .tooltip-arrow{
            border-bottom-color: $white-color;
        }
    }
}

.tooltip-inner, .mat-tooltip{
    padding: 10px 15px;
    min-width: 130px;
}

.mat-tooltip{
    padding: 10px 10px;
    font-size: 14px !important;
    font-family: BMWRegular !important;
    text-align: justify;
}
// $font-size-h1:          3.3125rem;
// $font-size-h2:          2.25rem !default;
// $font-size-h3:          1.5625rem;
 $font-size-h4:          1.125rem !default;
// $font-size-h5:          1.0625rem !default;
// $font-size-h6:          0.75rem !default;
// $font-paragraph:        14px !default;
// $font-weight-light:     300 !default;
// $font-weight-default:   400 !default;
// $bmd-inverse-light:     rgba($white, 0.84) !default;
// $bmd-inverse-lighter:   rgba($white, 0.54) !default;

@media screen and (max-width: 600px) {
    .mat-table {
      overflow:scroll;
      max-height: 500px;
    
      .mat-header-row {
        flex-direction:row;
      }
    
      .mat-row, .mat-header-row {
        min-width: 100%;
        width: fit-content;    
      }  
    
      .mat-cell, .mat-header-cell {
        flex: auto;
        width: 120px;
        padding-right: 15px;    
      }
    }
  }
.tooltip-inner, .mat-tooltip {
  color: #fff !important;
  line-height: 1.5em;
  background: #fff;
  border: none;
  border-radius: 3px !important;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.tooltip.in {
  opacity: 1;
  -webkit-transform: translate3d(0, 0px, 0);
  -moz-transform: translate3d(0, 0px, 0);
  -o-transform: translate3d(0, 0px, 0);
  -ms-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0);
}

.tooltip {
  opacity: 0;
  transition: opacity, transform 0.2s ease;
  -webkit-transform: translate3d(0, 5px, 0);
  -moz-transform: translate3d(0, 5px, 0);
  -o-transform: translate3d(0, 5px, 0);
  -ms-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
}
.tooltip.left .tooltip-arrow {
  border-left-color: #fff;
}
.tooltip.right .tooltip-arrow {
  border-right-color: #fff;
}
.tooltip.top .tooltip-arrow {
  border-top-color: #fff;
}
.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #fff;
}

.tooltip-inner, .mat-tooltip {
  padding: 10px 15px;
  min-width: 130px;
}

.mat-tooltip {
  padding: 10px 10px;
  font-size: 14px !important;
  font-family: BMWRegular !important;
  text-align: justify;
}

@media screen and (max-width: 600px) {
  .mat-table {
    overflow: scroll;
    max-height: 500px;
  }
  .mat-table .mat-header-row {
    flex-direction: row;
  }
  .mat-table .mat-row, .mat-table .mat-header-row {
    min-width: 100%;
    width: fit-content;
  }
  .mat-table .mat-cell, .mat-table .mat-header-cell {
    flex: auto;
    width: 120px;
    padding-right: 15px;
  }
}
.card {
  font-size: 0.875rem;
}

.card {
  border: 0 !important;
  margin-bottom: 30px;
}
.card .card-category:not([class*=text-]) {
  color: #999999;
}
.card .card-category {
  margin-top: 10px;
}
.card .card-category .material-icons {
  position: relative;
  top: 8px;
  line-height: 0;
}
.card .form-check {
  margin-top: 15px;
}
.card .card-title {
  margin-top: 0.625rem;
}
.card .card-title:last-child {
  margin-bottom: 0;
}
.card.no-shadow .card-header-image,
.card.no-shadow .card-header-image img {
  box-shadow: none !important;
}
.card .card-body,
.card .card-footer {
  padding: 0.9375rem 1.875rem;
}
.card .card-body + .card-footer {
  padding-top: 0rem;
  border: 0;
  border-radius: 6px;
}
.card .card-footer {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 0;
}
.card .card-footer .author,
.card .card-footer .stats {
  display: inline-flex;
}
.card .card-footer .stats {
  color: #999999;
}
.card .card-footer .stats .material-icons {
  position: relative;
  top: -10px;
  margin-right: 3px;
  margin-left: 3px;
  font-size: 18px;
}
.card .card-header {
  border-bottom: none;
  background: transparent;
}
.card .card-header .title {
  color: #fff;
}
.card .card-header .nav-tabs {
  padding: 0;
}
.card .card-header.card-header-image {
  position: relative;
  padding: 0;
  z-index: 1;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: -30px;
  border-radius: 6px;
}
.card .card-header.card-header-image img {
  width: 100%;
  border-radius: 6px;
  pointer-events: none;
  box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.card .card-header.card-header-image .card-title {
  position: absolute;
  bottom: 15px;
  left: 15px;
  color: #fff;
  font-size: 1.125rem;
  text-shadow: 0 2px 5px rgba(33, 33, 33, 0.5);
}
.card .card-header.card-header-image .colored-shadow {
  transform: scale(0.94);
  top: 12px;
  filter: blur(12px);
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: -1;
  transition: opacity 0.45s;
  opacity: 0;
}
.card .card-header.card-header-image.no-shadow {
  box-shadow: none;
}
.card .card-header.card-header-image.no-shadow.shadow-normal {
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.card .card-header.card-header-image.no-shadow .colored-shadow {
  display: none !important;
}
.card .card-header-primary .card-icon,
.card .card-header-primary .card-text,
.card .card-header-primary:not(.card-header-icon):not(.card-header-text), .card.bg-primary, .card.card-rotate.bg-primary .front, .card.card-rotate.bg-primary .back {
  background: linear-gradient(60deg, #009ADA, #1976d2);
}
.card .card-header-info .card-icon,
.card .card-header-info .card-text,
.card .card-header-info:not(.card-header-icon):not(.card-header-text), .card.bg-info, .card.card-rotate.bg-info .front, .card.card-rotate.bg-info .back {
  background: linear-gradient(60deg, #26c6da, #00acc1);
}
.card .card-header-success .card-icon,
.card .card-header-success .card-text,
.card .card-header-success:not(.card-header-icon):not(.card-header-text), .card.bg-success, .card.card-rotate.bg-success .front, .card.card-rotate.bg-success .back {
  background: linear-gradient(60deg, #66bb6a, #43a047);
}
.card .card-header-warning .card-icon,
.card .card-header-warning .card-text,
.card .card-header-warning:not(.card-header-icon):not(.card-header-text), .card.bg-warning, .card.card-rotate.bg-warning .front, .card.card-rotate.bg-warning .back {
  background: linear-gradient(60deg, #ffa726, #fb8c00);
}
.card .card-header-danger .card-icon,
.card .card-header-danger .card-text,
.card .card-header-danger:not(.card-header-icon):not(.card-header-text), .card.bg-danger, .card.card-rotate.bg-danger .front, .card.card-rotate.bg-danger .back {
  background: linear-gradient(60deg, #ef5350, #e53935);
}
.card .card-header-rose .card-icon,
.card .card-header-rose .card-text,
.card .card-header-rose:not(.card-header-icon):not(.card-header-text), .card.bg-rose, .card.card-rotate.bg-rose .front, .card.card-rotate.bg-rose .back {
  background: linear-gradient(60deg, #ec407a, #d81b60);
}
.card .card-header-primary .card-icon,
.card .card-header-primary:not(.card-header-icon):not(.card-header-text),
.card .card-header-primary .card-text {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(0, 154, 218, 0.4);
}
.card .card-header-danger .card-icon,
.card .card-header-danger:not(.card-header-icon):not(.card-header-text),
.card .card-header-danger .card-text {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(244, 67, 54, 0.4);
}
.card .card-header-rose .card-icon,
.card .card-header-rose:not(.card-header-icon):not(.card-header-text),
.card .card-header-rose .card-text {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4);
}
.card .card-header-warning .card-icon,
.card .card-header-warning:not(.card-header-icon):not(.card-header-text),
.card .card-header-warning .card-text {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 152, 0, 0.4);
}
.card .card-header-info .card-icon,
.card .card-header-info:not(.card-header-icon):not(.card-header-text),
.card .card-header-info .card-text {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(0, 188, 212, 0.4);
}
.card .card-header-success .card-icon,
.card .card-header-success:not(.card-header-icon):not(.card-header-text),
.card .card-header-success .card-text {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(76, 175, 80, 0.4);
}
.card [class*=card-header-], .card[class*=bg-] {
  color: #fff;
}
.card [class*=card-header-] .card-title a,
.card [class*=card-header-] .card-title,
.card [class*=card-header-] .icon i, .card[class*=bg-] .card-title a,
.card[class*=bg-] .card-title,
.card[class*=bg-] .icon i {
  color: #fff;
}
.card [class*=card-header-] .icon i, .card[class*=bg-] .icon i {
  border-color: rgba(255, 255, 255, 0.25);
}
.card [class*=card-header-] .author a,
.card [class*=card-header-] .stats,
.card [class*=card-header-] .card-category,
.card [class*=card-header-] .card-description, .card[class*=bg-] .author a,
.card[class*=bg-] .stats,
.card[class*=bg-] .card-category,
.card[class*=bg-] .card-description {
  color: rgba(255, 255, 255, 0.8);
}
.card [class*=card-header-] .author a:hover, .card [class*=card-header-] .author a:focus, .card [class*=card-header-] .author a:active, .card[class*=bg-] .author a:hover, .card[class*=bg-] .author a:focus, .card[class*=bg-] .author a:active {
  color: #fff;
}
.card .author .avatar {
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 5px;
}
.card .author a {
  color: #3C4858;
  text-decoration: none;
}
.card .author a .ripple-container {
  display: none;
}
.card .card-category-social .fa {
  font-size: 24px;
  position: relative;
  margin-top: -4px;
  top: 2px;
  margin-right: 5px;
}
.card .card-category-social .material-icons {
  position: relative;
  top: 5px;
}
.card[class*=bg-], .card[class*=bg-] .card-body {
  border-radius: 6px;
}
.card[class*=bg-] h1 small,
.card[class*=bg-] h2 small,
.card[class*=bg-] h3 small, .card[class*=bg-] .card-body h1 small,
.card[class*=bg-] .card-body h2 small,
.card[class*=bg-] .card-body h3 small {
  color: rgba(255, 255, 255, 0.8);
}
.card .card-stats {
  background: transparent;
  display: flex;
}
.card .card-stats .author,
.card .card-stats .stats {
  display: inline-flex;
}

.card .table tr:first-child td {
  border-top: none;
}
.card .card-title {
  margin-top: 0;
  margin-bottom: 15px;
}
.card .card-body {
  padding: 0.9375rem 20px;
  position: relative;
}
.card .card-header {
  z-index: 3 !important;
}
.card .card-header .card-title {
  margin-bottom: 3px;
}
.card .card-header .card-category {
  margin: 0;
}
.card .card-header.card-header-text {
  display: inline-block;
}
.card .card-header.card-header-text:after {
  content: "";
  display: table;
}
.card .card-header.card-header-icon i, .card .card-header.card-header-text i {
  width: 33px;
  height: 33px;
  text-align: center;
  line-height: 33px;
}
.card .card-header.card-header-icon .card-title, .card .card-header.card-header-text .card-title {
  margin-top: 15px;
  color: #3C4858;
}
.card .card-header.card-header-icon h4, .card .card-header.card-header-text h4 {
  font-weight: 300;
}
.card .card-header.card-header-tabs .nav-tabs {
  background: transparent;
  padding: 0;
}
.card .card-header.card-header-tabs .nav-tabs-title {
  float: left;
  padding: 10px 10px 10px 0;
  line-height: 24px;
}
.card.card-plain .card-header.card-header-icon + .card-body .card-title, .card.card-plain .card-header.card-header-icon + .card-body .card-category {
  margin-top: -20px;
}
.card .card-actions {
  position: absolute;
  z-index: 1;
  top: -50px;
  width: calc(100% - 30px);
  left: 17px;
  right: 17px;
  text-align: center;
}
.card .card-actions .card-header {
  padding: 0;
  min-height: 160px;
}
.card .card-actions .btn {
  padding-left: 12px;
  padding-right: 12px;
}
.card .card-actions .fix-broken-card {
  position: absolute;
  top: -65px;
}
.card.card-chart .card-footer i:nth-child(1n+2) {
  width: 18px;
  text-align: center;
}
.card.card-chart .card-category {
  margin: 0;
}
.card .card-body + .card-footer,
.card .card-footer {
  padding: 0;
  padding-top: 10px;
  margin: 0 15px 10px;
  border-radius: 0;
  justify-content: space-between;
  align-items: center;
}
.card .card-body + .card-footer h6,
.card .card-footer h6 {
  width: 100%;
}
.card .card-body + .card-footer .stats,
.card .card-footer .stats {
  color: #999999;
  font-size: 12px;
  line-height: 22px;
}
.card .card-body + .card-footer .stats .card-category,
.card .card-footer .stats .card-category {
  padding-top: 7px;
  padding-bottom: 7px;
  margin: 0;
}
.card .card-body + .card-footer .stats .material-icons,
.card .card-footer .stats .material-icons {
  position: relative;
  top: 4px;
  font-size: 16px;
}
.card [class*=card-header-] {
  margin: 0px 15px 0;
  padding: 0;
  position: relative;
}
.card [class*=card-header-] .card-title + .card-category {
  color: rgba(255, 255, 255, 0.62);
}
.card [class*=card-header-] .card-title + .card-category a {
  color: #fff;
}
.card [class*=card-header-]:not(.card-header-icon):not(.card-header-text):not(.card-header-image) {
  border-radius: 3px;
  margin-top: -20px;
  padding: 15px;
}
.card [class*=card-header-] .card-icon,
.card [class*=card-header-] .card-text {
  border-radius: 3px;
  background-color: #999999;
  padding: 15px;
  margin-top: -20px;
  margin-right: 15px;
  float: left;
}
.card [class*=card-header-] .card-text {
  float: none;
  display: inline-block;
  margin-right: 0;
}
.card [class*=card-header-] .card-text .card-title {
  color: #fff;
  margin-top: 0;
}
.card [class*=card-header-] .ct-chart .card-title {
  color: #fff;
}
.card [class*=card-header-] .ct-chart .card-category {
  margin-bottom: 0;
  color: rgba(255, 255, 255, 0.62);
}
.card [class*=card-header-] .ct-chart .ct-label {
  color: rgba(255, 255, 255, 0.7);
}
.card [class*=card-header-] .ct-chart .ct-grid {
  stroke: rgba(255, 255, 255, 0.2);
}
.card [class*=card-header-] .ct-chart .ct-series-a .ct-point,
.card [class*=card-header-] .ct-chart .ct-series-a .ct-line,
.card [class*=card-header-] .ct-chart .ct-series-a .ct-bar,
.card [class*=card-header-] .ct-chart .ct-series-a .ct-slice-donut {
  stroke: rgba(255, 255, 255, 0.8);
}
.card [class*=card-header-] .ct-chart .ct-series-a .ct-slice-pie,
.card [class*=card-header-] .ct-chart .ct-series-a .ct-area {
  fill: rgba(255, 255, 255, 0.4);
}
.card [class*=card-header-] .ct-chart .ct-series-a .ct-bar {
  stroke-width: 10px;
}
.card [class*=card-header-] .ct-chart .ct-point {
  stroke-width: 10px;
  stroke-linecap: round;
}
.card [class*=card-header-] .ct-chart .ct-line {
  fill: none;
  stroke-width: 4px;
}
.card [data-header-animation=true] {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
  -moz-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
  -o-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
  -ms-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
  transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
}
.card:hover [data-header-animation=true] {
  -webkit-transform: translate3d(0, -50px, 0);
  -moz-transform: translate3d(0, -50px, 0);
  -o-transform: translate3d(0, -50px, 0);
  -ms-transform: translate3d(0, -50px, 0);
  transform: translate3d(0, -50px, 0);
}
.card .map {
  height: 280px;
  border-radius: 6px;
  margin-top: 15px;
}
.card .map.map-big {
  height: 420px;
}
.card .card-body.table-full-width {
  padding: 0;
}
.card .card-plain .card-header-icon {
  margin-right: 15px !important;
}

.table-sales {
  margin-top: 40px;
}

.iframe-container {
  width: 100%;
}
.iframe-container iframe {
  width: 100%;
  height: 500px;
  border: 0;
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.card-wizard .nav.nav-pills .nav-item {
  margin: 0;
}
.card-wizard .nav.nav-pills .nav-item .nav-link {
  padding: 6px 15px !important;
}
.card-wizard .nav-pills:not(.flex-column) .nav-item + .nav-item:not(:first-child) {
  margin-left: 0;
}
.card-wizard .nav-item .nav-link.active,
.card-wizard .nav-item .nav-link:hover,
.card-wizard .nav-item .nav-link:focus {
  background-color: inherit !important;
  box-shadow: none !important;
}
.card-wizard .input-group-text {
  padding: 6px 15px 0px !important;
}
.card-wizard .card-footer {
  border-top: none !important;
}

.card-chart .card-body + .card-footer,
.card-product .card-body + .card-footer {
  border-top: 1px solid #eee;
}

.card-product .price {
  color: inherit;
}

.card-collapse {
  margin-bottom: 15px;
}
.card-collapse .card .card-header a[aria-expanded=true] {
  color: #e91e63;
}

.ZD-CUSTOM-hide {
  display: none;
}

#ZD-CUSTOM-chat-btn {
  background: #1c69d4;
  color: white;
  font-weight: bold;
  border-radius: 25px;
  position: fixed;
  bottom: 25px;
  right: 25px;
  padding: 13px 22px !important;
  font-size: 1rem;
}

#ZD-CUSTOM-chat-btn:focus {
  outline: 0;
}

#ZD-CUSTOM-chat-btn:hover {
  background: #0653b6;
  cursor: pointer;
}

#ZD-CUSTOM-privacy-policy {
  position: fixed;
  bottom: 20px;
  right: 20px;
  max-width: 342px;
  max-height: 50%;
  box-shadow: 0 0 0.4rem 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  font-size: 14px;
}

#ZD-CUSTOM-privacy-policy #ZD-CUSTOM-card-title {
  background: #1c69d4;
  color: white;
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
  border-radius: 0.5rem 0.5rem 0 0;
}

#ZD-CUSTOM-privacy-policy #ZD-CUSTOM-card-title #ZD-CUSTOM-minimize {
  position: absolute;
  right: 10px;
  top: 0;
  padding: 0 10px 10px;
  cursor: pointer;
  border-radius: 0.5rem;
  transition: background-color 0.25s ease-in-out;
}

#ZD-CUSTOM-privacy-policy #ZD-CUSTOM-card-title #ZD-CUSTOM-privacy-policy #ZD-CUSTOM-card-footer {
  display: flex;
  justify-content: space-between;
  background-color: white;
  align-items: center;
  border-top: 0.07857rem solid #e9ebed;
  box-shadow: 0 -0.07143rem 0.85714rem rgba(0, 0, 0, 0.08);
}

#ZD-CUSTOM-privacy-policy #ZD-CUSTOM-card-footer #ZD-CUSTOM-checkbox {
  margin-bottom: 0;
}

#ZD-CUSTOM-privacy-policy #ZD-CUSTOM-card-footer #ZD-CUSTOM-checkbox #ZD-CUSTOM-i-agree-checkbox {
  vertical-align: middle;
}

#ZD-CUSTOM-privacy-policy #ZD-CUSTOM-card-footer #ZD-CUSTOM-checkbox #ZD-CUSTOM-i-agree-text {
  vertical-align: text-top;
}

#ZD-CUSTOM-privacy-policy #ZD-CUSTOM-card-footer #ZD-CUSTOM-submit-button {
  float: right;
  background: #1c69d4;
  font-size: inherit;
}

#ZD-CUSTOM-privacy-policy #ZD-CUSTOM-card-footer #ZD-CUSTOM-submit-button:hover {
  background: #0653b6;
}

@media only screen and (max-width: 600px) {
  #ZD-CUSTOM-privacy-policy {
    max-width: calc(100% - 40px);
    max-height: calc(100% - 40px);
  }
}